/**
 * Post.jsx
 * The page that displays a post.
 */

import {useState, useCallback, useEffect} from 'react';
import { useParams } from "react-router-dom";
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import bash from 'highlight.js/lib/languages/bash';
import php from 'highlight.js/lib/languages/php';
import css from 'highlight.js/lib/languages/css';
import dart from 'highlight.js/lib/languages/dart';
import "highlight.js/styles/atom-one-dark-reasonable.css";

import {FloatingSidebar} from '#components/FloatingSidebar';
import {useNavHelper} from '#hooks/useNavHelper';
import { APIRequest } from '#modules/APIRequest';
import { AuthPage } from '#components/AuthPage';
import { SidebarPosts } from '#components/SidebarPosts';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('php', php);
hljs.registerLanguage('python', php);
hljs.registerLanguage('java', php);
hljs.registerLanguage('css', css);
hljs.registerLanguage('dart', dart);

export function Post(){
    const navigate = useNavHelper();
    const { slugOrId } = useParams();
    const [post, setPost] = useState();

	const setPostRef = useCallback(node=>{
		if(node){
			node.querySelectorAll("pre code").forEach(el=>{
				if(!el.dataset.highlighted){
					hljs.highlightElement(el);
				}
			});
		}	
	});

    useEffect(()=>{
        if(slugOrId) (async ()=>{
            let res;
            if(/^\d+$/.test(slugOrId)){
                res = await new APIRequest(`Post/${slugOrId}`).get();
            }else{
                res = await new APIRequest(`Post`).get({slug:slugOrId});
            }
            if(res.has_error){
                navigate('/404')
            }
            setPost(res.data);
        })();
    }, [slugOrId]);

    let component = (<p>Loading</p>);

    if(post){
        component = (<>
		<h3>{post.post.title}</h3>
			<small>by {post.author.display_name} on {new Date(post.post.create_ts * 1000).toLocaleDateString()}</small>
			<hr />

			<div className='row'>
				<div className='col-md-9'>
					<div ref={setPostRef} dangerouslySetInnerHTML={{__html: post.post.body}}></div>
					<br/>
				</div>
				<div className='col-md-3'>
					<FloatingSidebar>
						<SidebarPosts />
					</FloatingSidebar>
				</div>
			</div>

		</>);

        if(post.post.published != '1'){
            component = (<AuthPage>{component}</AuthPage>);
        }
    }

	return (<>{component}</>);
} 